
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import AddSupplierDrawer from '@/layout/header/partials/course/AddSupplierDrawer.vue';
import EditproductInfoDrawer from '@/layout/header/partials/course/EditproductInfoDrawer.vue';
import SetupProductInfoDrawer from '@/layout/header/partials/course/SetupProductInfoDrawer.vue';
import SetupRawProductInfoDrawer from '@/layout/header/partials/course/SetupRawProductInfoDrawer.vue';
import ViewProductDrawer from '@/layout/header/partials/course/ViewProductDrawer.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { object } from 'yup/lib/locale';
import { VueCookieNext } from 'vue-cookie-next';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';


export default defineComponent({
  name: 'supplier-list',
  components: {
    Datatable,
    AddSupplierDrawer,
    // EditproductInfoDrawer,
    // SetupProductInfoDrawer,
    // SetupRawProductInfoDrawer,
    // ViewProductDrawer,
  },
  data() {
    return {
      load: false,
      componentKey: 0,
      lists: [],
      search: '',
      tableData: [],
      data: {},
    };
  },
  async created() {
    if (VueCookieNext.getCookie('finman_token') != null) {
      await this.getSupplierData();
      this.emitter.on('supplier-added', async () => {
        await this.getSupplierData();
      });
      $(this.$refs.supplierList).DataTable({
        paging:false
      });
    } else {
      this.$router.push('/404');
    }
  },
  methods: {
    async getSupplierData() {
      await ApiService.get('finmanInvoice/supplier/list')
        .then((response) => {
          this.tableData = response.data.data;
          this.componentKey += 1;
          this.load = true;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    editProduct(data) {
      this.emitter.emit('supplier-edit-type', data);
    },

    Deleteproduct(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('finmanInvoice/supplier/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('supplier-added', true);
              Swal.fire(response.data.message, response.data.data, response.data.status);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },

    downloadExcel() {
      const worksheet = XLSX.utils.table_to_sheet(document.getElementById('supplier-table'));
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Suppliers');
      XLSX.writeFile(workbook, 'Suppliers.xlsx');
    },

  },
});
